* {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  font-smooth: auto;
  -moz-osx-font-smoothing: auto; }

body {
  font-family: 'Roboto', sans-serif;
  color: #474443; }
  body.no-scroll {
    overflow: hidden; }

.container {
  max-width: 1200px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative; }
  @media (min-width: 768px) {
    .container {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (min-width: 992px) {
    .container {
      padding-left: 118px;
      padding-right: 118px; } }

.mt-10 {
  margin-top: 10px; }

.d-block {
  display: block; }

.d-inline {
  display: inline; }

.d-inline-block, a.btn {
  display: inline-block; }

.d-flex, #home .catalyzing-action .ca-top, #home .catalyzing-action .ca-top .illo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.d-flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.d-flex--row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }

.d-flex--justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.d-flex--align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.d-flex-size-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.d-flex-size-2 {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2; }

.d-flex-size-3 {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3; }

.d-flex-size-4 {
  -webkit-box-flex: 4;
      -ms-flex: 4;
          flex: 4; }

.d-flex-size-5 {
  -webkit-box-flex: 5;
      -ms-flex: 5;
          flex: 5; }

.d-flex-size-6 {
  -webkit-box-flex: 6;
      -ms-flex: 6;
          flex: 6; }

.d-flex-size-7 {
  -webkit-box-flex: 7;
      -ms-flex: 7;
          flex: 7; }

.fw-regular {
  font-weight: 400; }

.fw-medium, h3, #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-title, #actions .ac__title {
  font-weight: 500; }

.fw-bold, .btn, h1, h2, #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-authors ul > li:first-child, #home .events .ev-events .ev-upcoming h4, #home .events .ev-events .ev-past h4, #home .events .ev-events .ev-upcoming .ev-event-link, #home .events .ev-events .ev-past .ev-event-link {
  font-weight: 700; }

.fw-black {
  font-weight: 900; }

.fs-24, h3, #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-title {
  font-size: 24px;
  line-height: 32px; }

.fs-16, .btn, footer, #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner, #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-authors, #home .events .ev-events, #home .events .ev-events .ev-upcoming h4, #home .events .ev-events .ev-past h4 {
  font-size: 16px;
  line-height: 22px; }

.fs-54, h1 {
  font-size: 30px;
  line-height: 40px; }
  @media (min-width: 768px) {
    .fs-54, h1 {
      font-size: 54px;
      line-height: 72px; } }

.fs-36, h2, #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content--transparent .paper-title, #actions .ac__title {
  font-size: 36px;
  line-height: 43px; }

.fs-10 {
  font-size: 10px;
  line-height: 13px; }

.c-green-1, .btn--green-invert, #actions .ac__title {
  color: #009b4c; }

.c-green-2 {
  color: #acce22; }

.c-white, .btn--green, .btn--orange {
  color: #fff; }

.c-orange-1, .btn--orange--invert, #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-title {
  color: #f08519; }

.c-grey-1 {
  color: #757475; }

.c-blue-1 {
  color: #006cae; }

.bgc-green-1, .btn--green {
  background-color: #009b4c; }

.bgc-green-2 {
  background-color: #acce22; }

.bgc-white, .btn--green-invert, .btn--orange--invert {
  background-color: #fff; }

.bgc-yellow-1 {
  background-color: #ffe900; }

.bgc-orange-1, .btn--orange {
  background-color: #f08519; }

.bgc-blue-1 {
  background-color: #006cae; }

.bgc-green-alpha {
  background-color: rgba(169, 203, 56, 0.6); }

.btn {
  border-radius: 30px;
  text-transform: uppercase;
  padding: 16px 30px;
  cursor: pointer;
  border: 2px solid;
  -webkit-transition: 0.2s color, 0.2s background-color, 0.2s border-color;
  transition: 0.2s color, 0.2s background-color, 0.2s border-color; }

a.btn[disabled] {
  cursor: not-allowed; }

.btn--green {
  border-color: #009b4c; }
  .btn--green:hover {
    border-color: #0D7D46;
    background-color: #0D7D46; }

.btn--green-invert {
  border-color: #009b4c; }
  .btn--green-invert:hover {
    border-color: #0D7D46;
    color: #0D7D46; }

.btn--orange {
  border-color: #f08519; }
  .btn--orange:hover {
    border-color: #EC6B21;
    background-color: #EC6B21; }

.btn--orange--invert {
  border-color: #f08519; }
  .btn--orange--invert:hover {
    border-color: #EC6B21;
    color: #EC6B21; }

.btn--green {
  border-color: #009b4c; }
  .btn--green:hover {
    border-color: #0D7D46;
    background-color: #0D7D46; }

img.fullwidth {
  width: 100%;
  height: auto; }

a {
  -webkit-transition: .2s color;
  transition: .2s color; }

em {
  font-style: italic; }

header {
  background: -webkit-gradient(linear, left top, left bottom, from(#009b4c), to(#acce22));
  background: linear-gradient(#009b4c, #acce22);
  position: relative;
  width: 100%;
  z-index: 5; }
  header .container {
    height: 55px; }
    @media (min-width: 768px) {
      header .container {
        height: 100px; } }
  header .logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    bottom: 0;
    -webkit-transform: translate(-8px, 75%);
            transform: translate(-8px, 75%);
    position: absolute; }
    @media (min-width: 768px) {
      header .logos {
        -webkit-transform: translate(-8px, 50%);
                transform: translate(-8px, 50%); } }
  header .initiative-logo {
    background: #fff;
    width: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 120px;
    border-radius: 0 61px 61px 0;
    margin-left: -50px;
    z-index: -1;
    -webkit-box-shadow: 8px 1px 12px rgba(0, 0, 0, 0.24);
            box-shadow: 8px 1px 12px rgba(0, 0, 0, 0.24); }
    header .initiative-logo img {
      width: 70px;
      height: 100px; }

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 100; }
  .navbar button {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 30px;
    right: 20px;
    padding: 16px;
    border-radius: 25px;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    z-index: 25;
    cursor: pointer; }
    @media (min-width: 576px) {
      .navbar button {
        display: none; } }
    .navbar button .line {
      position: relative;
      width: 18px;
      height: 2px;
      background-color: #009b4c;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      top: 0; }
    .navbar button.open .line:nth-child(2) {
      opacity: 0; }
    .navbar button.open .line:first-child {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: 6px; }
    .navbar button.open .line:last-child {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      top: -6px; }
  .navbar .navbar-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    pointer-events: none;
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-color: #0d7d46;
    opacity: 0;
    z-index: 20;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out; }
    .navbar .navbar-inner.open {
      opacity: 1;
      pointer-events: all; }
    @media (min-width: 576px) {
      .navbar .navbar-inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        opacity: 1;
        pointer-events: all;
        position: absolute;
        padding: 16px;
        width: 300px;
        height: 50px;
        top: 30px;
        right: 20px;
        border-radius: 30px;
        background: #fff;
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); } }
    @media (min-width: 768px) {
      .navbar .navbar-inner {
        top: 70px;
        right: 40px; } }
    @media (min-width: 992px) {
      .navbar .navbar-inner {
        height: 60px;
        right: 118px; } }
    .navbar .navbar-inner ul {
      list-style: none;
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: white; }
      @media (min-width: 576px) {
        .navbar .navbar-inner ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
      @media (min-width: 992px) {
        .navbar .navbar-inner ul {
          height: 28px; } }
      .navbar .navbar-inner ul li {
        margin-bottom: 50px; }
        .navbar .navbar-inner ul li:last-of-type {
          margin-bottom: 0; }
        .navbar .navbar-inner ul li.active {
          color: #fff; }
        @media (min-width: 576px) {
          .navbar .navbar-inner ul li {
            color: #474443;
            margin-bottom: 0; }
            .navbar .navbar-inner ul li.active {
              color: #009b4c;
              font-weight: bold; } }

footer {
  padding: 60px 0;
  color: #fff; }
  @media (min-width: 768px) {
    footer {
      padding: 65px 0; } }
  @media (min-width: 768px) {
    footer {
      padding: 75px 0; } }
  @media (min-width: 768px) {
    footer {
      padding: 87px 0; } }
  footer .d-flex-size-3 {
    -webkit-box-flex: 3;
        -ms-flex: 3 0 auto;
            flex: 3 0 auto; }
    @media (min-width: 768px) {
      footer .d-flex-size-3 {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1; } }
  footer .d-flex-size-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
    @media (min-width: 768px) {
      footer .d-flex-size-1 {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1; } }
  footer a {
    text-decoration: underline; }
  footer .container.d-flex, footer #home .catalyzing-action .container.ca-top, #home .catalyzing-action footer .container.ca-top, footer #home .catalyzing-action .ca-top .container.illo-container, #home .catalyzing-action .ca-top footer .container.illo-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 26px; }
    @media (min-width: 768px) {
      footer .container.d-flex, footer #home .catalyzing-action .container.ca-top, #home .catalyzing-action footer .container.ca-top, footer #home .catalyzing-action .ca-top .container.illo-container, #home .catalyzing-action .ca-top footer .container.illo-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
    footer .container.d-flex > div, footer #home .catalyzing-action .container.ca-top > div, #home .catalyzing-action footer .container.ca-top > div, footer #home .catalyzing-action .ca-top .container.illo-container > div, #home .catalyzing-action .ca-top footer .container.illo-container > div {
      margin-left: 0;
      margin-right: 0; }
      @media (min-width: 768px) {
        footer .container.d-flex > div, footer #home .catalyzing-action .container.ca-top > div, #home .catalyzing-action footer .container.ca-top > div, footer #home .catalyzing-action .ca-top .container.illo-container > div, #home .catalyzing-action .ca-top footer .container.illo-container > div {
          margin-left: 10px;
          margin-right: 10px; }
          footer .container.d-flex > div:first-of-type, footer #home .catalyzing-action .container.ca-top > div:first-of-type, #home .catalyzing-action footer .container.ca-top > div:first-of-type, footer #home .catalyzing-action .ca-top .container.illo-container > div:first-of-type, #home .catalyzing-action .ca-top footer .container.illo-container > div:first-of-type {
            margin-left: 0; }
          footer .container.d-flex > div:last-of-type, footer #home .catalyzing-action .container.ca-top > div:last-of-type, #home .catalyzing-action footer .container.ca-top > div:last-of-type, footer #home .catalyzing-action .ca-top .container.illo-container > div:last-of-type, #home .catalyzing-action .ca-top footer .container.illo-container > div:last-of-type {
            margin-right: 0; } }
      @media (min-width: 992px) {
        footer .container.d-flex > div, footer #home .catalyzing-action .container.ca-top > div, #home .catalyzing-action footer .container.ca-top > div, footer #home .catalyzing-action .ca-top .container.illo-container > div, #home .catalyzing-action .ca-top footer .container.illo-container > div {
          margin-left: 20px;
          margin-right: 20px; }
          footer .container.d-flex > div:first-of-type, footer #home .catalyzing-action .container.ca-top > div:first-of-type, #home .catalyzing-action footer .container.ca-top > div:first-of-type, footer #home .catalyzing-action .ca-top .container.illo-container > div:first-of-type, #home .catalyzing-action .ca-top footer .container.illo-container > div:first-of-type {
            margin-left: 0; }
          footer .container.d-flex > div:last-of-type, footer #home .catalyzing-action .container.ca-top > div:last-of-type, #home .catalyzing-action footer .container.ca-top > div:last-of-type, footer #home .catalyzing-action .ca-top .container.illo-container > div:last-of-type, #home .catalyzing-action .ca-top footer .container.illo-container > div:last-of-type {
            margin-right: 0; } }
  footer p, footer ul {
    margin-bottom: 26px; }
    @media (min-width: 768px) {
      footer p, footer ul {
        margin-bottom: 0; } }
  footer .nowrap {
    white-space: nowrap; }

@-webkit-keyframes halopulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: .1; } }

@keyframes halopulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: .1; } }

.download-notification {
  padding: 20px;
  background: -webkit-gradient(linear, left top, right top, from(#009b4c), color-stop(120%, #acce22));
  background: linear-gradient(to right, #009b4c, #acce22 120%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .download-notification a {
    color: #fff;
    font-size: 20px;
    line-height: 1.6;
    width: 100%;
    text-align: center; }
    .download-notification a:hover {
      text-decoration: underline; }

#home {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    #home {
      padding-top: 40px;
      padding-bottom: 110px; } }
  #home .hero {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 60px; }
    @media (min-width: 768px) {
      #home .hero {
        padding-bottom: 110px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
    #home .hero h1 {
      font-size: 30px;
      line-height: 40px; }
      @media (min-width: 768px) {
        #home .hero h1 {
          font-size: 54px;
          line-height: 72px; } }
    #home .hero p {
      margin: 30px 0;
      font-size: 20px;
      line-height: 26px; }
      @media (min-width: 768px) {
        #home .hero p {
          font-size: 24px;
          line-height: 32px; } }
    #home .hero .hero-left .hero-logos {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row; }
      #home .hero .hero-left .hero-logos .initiative-logo {
        width: 60px;
        height: auto;
        margin-left: 30px; }
    #home .hero .hero-left .logo {
      margin: 30px 0; }
    #home .hero .hero-right {
      padding-top: 30px; }
      @media (min-width: 992px) {
        #home .hero .hero-right {
          padding-top: 0;
          padding-left: 30px; } }
    #home .hero .btn {
      display: block;
      margin-top: 15px;
      text-align: center; }
      @media (min-width: 1200px) {
        #home .hero .btn {
          display: inline-block;
          text-align: left; }
          #home .hero .btn:nth-of-type(2) {
            margin-left: 15px; } }
    #home .hero .hero-img-desktop {
      display: none; }
      @media (min-width: 768px) {
        #home .hero .hero-img-desktop {
          display: block; } }
    @media (min-width: 768px) {
      #home .hero .hero-img-mobile {
        display: none; } }
  #home .action-plan {
    background-color: rgba(172, 206, 34, 0.1);
    padding-top: 60px;
    padding-bottom: 60px; }
    @media (min-width: 576px) {
      #home .action-plan {
        padding-top: 130px;
        padding-bottom: 130px; } }
    @media (min-width: 992px) {
      #home .action-plan .ap-top {
        width: 50%; } }
    #home .action-plan .ap-top h2 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 36px; }
      @media (min-width: 576px) {
        #home .action-plan .ap-top h2 {
          font-size: 36px;
          line-height: 48px; } }
    #home .action-plan .ap-top p {
      font-size: 20px;
      line-height: 26px; }
      @media (min-width: 576px) {
        #home .action-plan .ap-top p {
          font-size: 24px;
          line-height: 32px; } }
    #home .action-plan .ap-bottom {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-top: 53px;
      width: 100%; }
      @media (min-width: 768px) {
        #home .action-plan .ap-bottom {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          width: calc(100% + 80px);
          margin-right: -40px;
          margin-left: -40px;
          margin-top: 33px; } }
      @media (min-width: 1200px) {
        #home .action-plan .ap-bottom {
          width: 100%;
          margin-left: 0;
          margin-right: 0; } }
      #home .action-plan .ap-bottom .ap-cycle {
        display: none;
        width: 460px;
        height: 460px;
        background: #acce22; }
        @media (min-width: 768px) {
          #home .action-plan .ap-bottom .ap-cycle {
            display: block;
            -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
            padding: 30px;
            height: auto;
            z-index: 100;
            margin-right: -30px; } }
        @media (min-width: 992px) {
          #home .action-plan .ap-bottom .ap-cycle {
            -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
            height: auto; } }
        #home .action-plan .ap-bottom .ap-cycle .inner {
          display: block;
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 100%; }
        #home .action-plan .ap-bottom .ap-cycle svg {
          width: 100%;
          height: 100%;
          max-width: 335px;
          max-height: 341px; }
          @media (min-width: 768px) {
            #home .action-plan .ap-bottom .ap-cycle svg {
              position: absolute;
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
              left: 0;
              top: 0; } }
      #home .action-plan .ap-bottom .ap-cycle--mobile {
        width: 60px; }
        @media (min-width: 768px) {
          #home .action-plan .ap-bottom .ap-cycle--mobile {
            display: none; } }
      @media (min-width: 768px) {
        #home .action-plan .ap-bottom .ap-cycle-controls--mobile {
          display: none !important; } }
      #home .action-plan .ap-bottom .ap-cycle-copy {
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -ms-flex-negative: 1;
            flex-shrink: 1;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
        background: -webkit-gradient(linear, left top, left bottom, from(#009b4c), to(#acce22));
        background: linear-gradient(#009b4c, #acce22);
        margin: 0 -20px;
        padding: 20px; }
        @media (min-width: 576px) {
          #home .action-plan .ap-bottom .ap-cycle-copy {
            margin: inherit;
            padding: 30px; } }
        @media (min-width: 768px) {
          #home .action-plan .ap-bottom .ap-cycle-copy {
            -webkit-box-flex: 0;
                -ms-flex-positive: 0;
                    flex-grow: 0;
            -ms-flex-negative: 0;
                flex-shrink: 0;
            -ms-flex-preferred-size: auto;
                flex-basis: auto;
            margin: 0; } }
        #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-panel {
          display: none; }
          @media (min-width: 576px) {
            #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-panel {
              height: 560px;
              width: 380px; } }
          @media (min-width: 768px) {
            #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-panel {
              width: 346px;
              height: 617px; } }
          @media (min-width: 992px) {
            #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-panel {
              height: 560px;
              width: 380px; } }
          #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-panel--active {
            display: block; }
        #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner {
          background: #fff;
          padding: 16px;
          -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
          @media (min-width: 576px) {
            #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner {
              padding: 60px 47px 19px 47px; } }
          #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner h3 {
            margin: 20px 0; }
          #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner hr {
            background: #474443;
            margin: 34px 0 10px 0;
            height: 1px;
            opacity: .2;
            border: none; }
          #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner .ap-cycle-controls {
            display: none;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end; }
            #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner .ap-cycle-controls--mobile {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                      justify-content: space-between; }
              @media (min-width: 576px) {
                #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner .ap-cycle-controls--mobile {
                  display: none; } }
            @media (min-width: 576px) {
              #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner .ap-cycle-controls {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex; } }
            #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner .ap-cycle-controls a {
              cursor: pointer;
              color: #009b4c;
              margin-left: 10px; }
              #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner .ap-cycle-controls a:hover {
                color: #0D7D46; }
              #home .action-plan .ap-bottom .ap-cycle-copy .ap-cycle-copy-inner .ap-cycle-controls a[disabled] {
                cursor: not-allowed;
                opacity: .2;
                color: inherit !important; }
    #home .action-plan #interactive .dot, #home .action-plan #interactive .halo {
      fill: #fff;
      -webkit-transition: .5s fill;
      transition: .5s fill;
      cursor: pointer; }
    #home .action-plan #interactive .dot.active, #home .action-plan #interactive .dot:hover {
      fill: #fdd100; }
    #home .action-plan #interactive .halo.active {
      fill: #ffe900;
      visibility: visible !important;
      -webkit-transform: none !important;
              transform: none !important;
      opacity: .1 !important; }
    #home .action-plan #interactive2 .dot, #home .action-plan #interactive2 .halo {
      fill: #fff;
      -webkit-transition: .5s fill;
      transition: .5s fill; }
      #home .action-plan #interactive2 .dot[data-panel='6'], #home .action-plan #interactive2 .halo[data-panel='6'] {
        fill: #ACCE22; }
    #home .action-plan #interactive2 .dot.active {
      fill: #fdd100; }
    #home .action-plan #interactive2 .halo.active {
      fill: #ffe900; }
  #home .catalyzing-action {
    padding-top: 60px; }
    @media (min-width: 768px) {
      #home .catalyzing-action {
        padding-top: 130px; } }
    #home .catalyzing-action h2 {
      margin: 15px 0;
      font-size: 24px;
      line-height: 32px; }
      @media (min-width: 576px) {
        #home .catalyzing-action h2 {
          margin-bottom: 30px;
          font-size: 36px;
          line-height: 48px; } }
    #home .catalyzing-action p {
      font-size: 20px;
      line-height: 26px; }
      @media (min-width: 576px) {
        #home .catalyzing-action p {
          font-size: 24px;
          line-height: 32px; } }
    #home .catalyzing-action .ca-top {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; }
      @media (min-width: 576px) {
        #home .catalyzing-action .ca-top {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row; } }
      #home .catalyzing-action .ca-top .illo-container {
        padding-top: 30px;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; }
        @media (min-width: 576px) {
          #home .catalyzing-action .ca-top .illo-container {
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end; } }
    #home .catalyzing-action .ca-bottom {
      margin-top: 90px;
      padding-bottom: 90px; }
      #home .catalyzing-action .ca-bottom .illo-container {
        position: relative;
        padding-bottom: 44px; }
        #home .catalyzing-action .ca-bottom .illo-container > img {
          position: absolute;
          top: 0;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
      #home .catalyzing-action .ca-bottom .copy-container {
        padding: 17px;
        text-align: center; }
        @media (min-width: 768px) {
          #home .catalyzing-action .ca-bottom .copy-container {
            padding: 0;
            margin: 44px auto;
            width: 60%; } }
        #home .catalyzing-action .ca-bottom .copy-container p {
          font-size: 20px;
          line-height: 26px; }
          @media (min-width: 576px) {
            #home .catalyzing-action .ca-bottom .copy-container p {
              font-size: 24px;
              line-height: 32px; } }
      #home .catalyzing-action .ca-bottom .ca-papers {
        margin: 0 -20px; }
        @media (min-width: 992px) {
          #home .catalyzing-action .ca-bottom .ca-papers {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            width: calc(100% + 120px);
            margin-left: -60px; } }
        #home .catalyzing-action .ca-bottom .ca-papers .ca-paper {
          background: -webkit-gradient(linear, left top, left bottom, from(#f08519), to(#fdd100));
          background: linear-gradient(#f08519, #fdd100);
          padding: 20px;
          margin-bottom: 16px; }
          @media (min-width: 992px) {
            #home .catalyzing-action .ca-bottom .ca-papers .ca-paper {
              padding: 30px;
              width: calc(50% - 8px); } }
          #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content {
            background: #fff;
            -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            padding: 17px;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column; }
            @media (min-width: 576px) {
              #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content {
                padding: 35px; } }
            #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content--transparent {
              background: none;
              -webkit-box-shadow: none;
                      box-shadow: none; }
              #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content--transparent .paper-title {
                line-height: 48px;
                color: #fff !important; }
              #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content--transparent .btn {
                border-color: #fff;
                color: #fff;
                background: none; }
            #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-title {
              margin: 16px 0; }
            #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-authors {
              -webkit-box-flex: 1;
                  -ms-flex: 1 1 auto;
                      flex: 1 1 auto; }
              #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-authors ul {
                list-style: none; }
                #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-authors ul > li {
                  display: inline; }
                  #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-authors ul > li:after {
                    content: ', '; }
                  #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-authors ul > li:last-child:after {
                    content: ''; }
            #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-cta {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                      flex-direction: column;
              margin-top: 30px; }
              @media (min-width: 576px) {
                #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-cta {
                  margin-top: 60px;
                  -webkit-box-orient: horizontal;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: row;
                          flex-direction: row;
                  -webkit-box-pack: justify;
                      -ms-flex-pack: justify;
                          justify-content: space-between; } }
              #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-cta .btn {
                text-align: center;
                margin-top: 15px; }
                @media (min-width: 576px) {
                  #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-cta .btn {
                    margin-top: 0;
                    width: calc(50% - 8px); } }
                #home .catalyzing-action .ca-bottom .ca-papers .ca-paper .ca-paper-content .paper-cta .btn.d-block {
                  width: 100%;
                  pointer-events: none; }
  #home .events {
    background-color: rgba(172, 206, 34, 0.1);
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 60px 0; }
    @media (min-width: 768px) {
      #home .events {
        padding-top: 130px;
        padding-bottom: 130px;
        margin: 130px 0; } }
    #home .events .container {
      position: relative; }
    #home .events h1 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px; }
      @media (min-width: 768px) {
        #home .events h1 {
          font-size: 36px;
          line-height: 48px; } }
    #home .events .ev-events-bg {
      display: block;
      position: absolute;
      background-color: #acce22;
      bottom: -110px;
      left: 0;
      width: 100%;
      height: 100%; }
      @media (min-width: 768px) {
        #home .events .ev-events-bg {
          top: 174px;
          left: 216px;
          width: calc(100% - 334px);
          height: calc(100% - 174px); } }
      #home .events .ev-events-bg .ev-events-bg-illo {
        position: absolute;
        bottom: 0;
        right: 0;
        -webkit-transform: translate(-30px, 0);
                transform: translate(-30px, 0); }
        #home .events .ev-events-bg .ev-events-bg-illo img {
          width: 106px; }
        @media (min-width: 768px) {
          #home .events .ev-events-bg .ev-events-bg-illo {
            -webkit-transform: translate(30px, 0);
                    transform: translate(30px, 0); }
            #home .events .ev-events-bg .ev-events-bg-illo img {
              width: inherit; } }
    #home .events .ev-events {
      position: relative; }
      @media (min-width: 768px) {
        #home .events .ev-events {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          padding: 0 30px 0 0; } }
      #home .events .ev-events .ev-upcoming, #home .events .ev-events .ev-past {
        padding: 30px;
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
        #home .events .ev-events .ev-upcoming h4, #home .events .ev-events .ev-past h4 {
          color: #acce22;
          text-transform: uppercase;
          margin-bottom: 15px; }
        #home .events .ev-events .ev-upcoming dl, #home .events .ev-events .ev-past dl {
          margin-top: 15px; }
          #home .events .ev-events .ev-upcoming dl dd, #home .events .ev-events .ev-past dl dd {
            margin-left: 0; }
            #home .events .ev-events .ev-upcoming dl dd::after, #home .events .ev-events .ev-past dl dd::after {
              display: block;
              width: 100%;
              height: 1px;
              background: #fff;
              opacity: .2;
              margin: 30px 0;
              content: ''; }
            #home .events .ev-events .ev-upcoming dl dd:last-child::after, #home .events .ev-events .ev-past dl dd:last-child::after {
              display: none; }
        #home .events .ev-events .ev-upcoming .ev-event-link, #home .events .ev-events .ev-past .ev-event-link {
          text-transform: uppercase;
          margin-top: 15px; }
      #home .events .ev-events .ev-upcoming {
        margin: 0 -20px 20px -20px; }
        @media (min-width: 768px) {
          #home .events .ev-events .ev-upcoming {
            width: calc(53% - 15px);
            margin: inherit;
            margin-bottom: 30px;
            padding-bottom: 120px;
            height: 100%; } }
      #home .events .ev-events .ev-past {
        margin-bottom: 130px; }
        @media (min-width: 768px) {
          #home .events .ev-events .ev-past {
            width: calc(47% - 15px);
            margin-top: 116px;
            margin-bottom: 192px; } }
        #home .events .ev-events .ev-past dl dd::after {
          background: #474443; }
        #home .events .ev-events .ev-past .ev-event-title a {
          text-decoration: underline; }
  #home .publications {
    margin: 60px 0; }
    #home .publications .container {
      padding: 0 37px; }
    @media (min-width: 768px) {
      #home .publications {
        margin: 130px 0; }
        #home .publications .container {
          padding: 0 110px; } }
    #home .publications h1 {
      font-size: 24px;
      line-height: 32px; }
      @media (min-width: 768px) {
        #home .publications h1 {
          font-size: 36px;
          line-height: 48px; } }
    #home .publications .publication-category-tag {
      text-transform: uppercase;
      padding: 7px 0;
      text-align: center;
      margin: 30px 0; }
      @media (min-width: 768px) {
        #home .publications .publication-category-tag {
          margin: 36px 0;
          width: 270px; } }
      #home .publications .publication-category-tag.bgc-green-1 + dl .publication-link a, #home .publications .publication-category-tag.btn--green + dl .publication-link a {
        color: #009b4c; }
        #home .publications .publication-category-tag.bgc-green-1 + dl .publication-link a:hover, #home .publications .publication-category-tag.btn--green + dl .publication-link a:hover {
          color: #0D7D46; }
      #home .publications .publication-category-tag.bgc-green-2 + dl .publication-link a {
        color: #acce22; }
        #home .publications .publication-category-tag.bgc-green-2 + dl .publication-link a:hover {
          color: #A5AC37; }
    @media (min-width: 768px) {
      #home .publications dl {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    #home .publications dl dd {
      margin: 0;
      margin-bottom: 30px; }
      @media (min-width: 768px) {
        #home .publications dl dd {
          width: calc(50% - 8px); } }
      #home .publications dl dd .publication-links {
        margin-top: 15px; }
        @media (min-width: 768px) {
          #home .publications dl dd .publication-links {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row; } }
        #home .publications dl dd .publication-links .publication-link {
          margin: 15px 0;
          font-weight: bold;
          text-transform: uppercase; }
          @media (min-width: 768px) {
            #home .publications dl dd .publication-links .publication-link {
              -webkit-box-flex: 1;
                  -ms-flex: 1;
                      flex: 1;
              margin: inherit; } }
      #home .publications dl dd::after {
        content: '';
        display: block;
        width: 100%;
        background: #757475;
        opacity: .5;
        height: 1px;
        margin: 15px 0; }

#about {
  overflow-x: hidden; }
  #about #our-goals {
    padding-top: 120px;
    background-color: rgba(172, 206, 34, 0.1); }
    @media (min-width: 768px) {
      #about #our-goals {
        padding-top: 90px; } }
    #about #our-goals a {
      color: #009b4c;
      text-decoration: underline; }
  #about h1 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      #about h1 {
        font-size: 36px;
        line-height: 48px; } }
  #about p {
    font-size: 16px;
    line-height: 22px; }
    #about p.small {
      font-size: .8em; }
  #about .intro {
    margin: 16px 16px 30px; }
    @media (min-width: 768px) {
      #about .intro {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 992px) {
      #about .intro {
        margin-botom: 35px; } }
    @media (min-width: 1200px) {
      #about .intro {
        margin-bottom: 40px; } }
    #about .intro p {
      width: 100%;
      max-width: 559px; }
      @media (min-width: 768px) {
        #about .intro p {
          width: 465px;
          max-width: none; } }
      @media (min-width: 992px) {
        #about .intro p {
          width: 65%; } }
      @media (min-width: 1200px) {
        #about .intro p {
          width: 572px; } }
  #about .global-goals {
    margin-left: -20px;
    margin-right: -20px;
    background: -webkit-gradient(linear, left top, left bottom, from(#009b4c), to(#acce22));
    background: linear-gradient(#009b4c, #acce22);
    width: calc(100% + 40px);
    padding: 30px 16px 16px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    @media (min-width: 768px) {
      #about .global-goals {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 25px;
        margin-left: 0;
        margin-right: 0;
        width: 100%; } }
    @media (min-width: 992px) {
      #about .global-goals {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 30px; } }
    #about .global-goals p.callout {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 30px;
      margin-left: 16px;
      margin-right: 16px; }
      #about .global-goals p.callout span {
        font-size: 40px;
        line-height: 50px; }
      #about .global-goals p.callout br.desktop {
        display: none; }
      @media (min-width: 768px) {
        #about .global-goals p.callout {
          -webkit-box-flex: 1.25;
              -ms-flex: 1.25;
                  flex: 1.25;
          font-size: 30px;
          line-height: 43px;
          margin-left: 0;
          margin-bottom: 0;
          margin-right: 30px; }
          #about .global-goals p.callout span:first-of-type {
            font-size: 75px;
            line-height: 80px; }
          #about .global-goals p.callout span:last-of-type {
            font-size: 50px;
            line-height: 65px; }
          #about .global-goals p.callout br.desktop {
            display: block; } }
      @media (min-width: 992px) {
        #about .global-goals p.callout {
          font-size: 36px;
          line-height: 48px; }
          #about .global-goals p.callout span:first-of-type {
            font-size: 95px;
            line-height: 100px; }
          #about .global-goals p.callout span:last-of-type {
            font-size: 54px;
            line-height: 72px; } }
    #about .global-goals .inner {
      background-color: white;
      padding: 16px 8px 8px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
              box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
      width: 100%; }
      @media (min-width: 768px) {
        #about .global-goals .inner {
          -webkit-box-flex: 3;
              -ms-flex: 3;
                  flex: 3;
          padding: 25px 17px 17px; } }
    #about .global-goals img.goals {
      width: 155px;
      margin-left: 8px; }
    #about .global-goals .icons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
    #about .global-goals img.icon {
      width: 100px;
      -ms-flex-negative: 1;
          flex-shrink: 1;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      margin: 8px; }
      @media (min-width: 576px) {
        #about .global-goals img.icon {
          width: calc(20% - 16px);
          max-width: 150px;
          -webkit-box-flex: 0;
              -ms-flex-positive: 0;
                  flex-grow: 0;
          -ms-flex-negative: 0;
              flex-shrink: 0; } }
      @media (min-width: 768px) {
        #about .global-goals img.icon {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 calc(1/4 * 100% - 16px);
                  flex: 1 1 calc(1/4 * 100% - 16px); } }
  #about .pros-and-cons {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    #about .pros-and-cons .left, #about .pros-and-cons .right {
      width: 100%; }
    #about .pros-and-cons .left {
      padding: 30px 16px; }
      @media (min-width: 768px) {
        #about .pros-and-cons .left {
          padding-left: 0;
          padding-right: 0;
          max-width: 545px; } }
      @media (min-width: 992px) {
        #about .pros-and-cons .left {
          width: 65%;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          padding-bottom: 105px;
          padding-top: 35px; } }
      @media (min-width: 1200px) {
        #about .pros-and-cons .left {
          padding-top: 40px; } }
    @media (min-width: 992px) {
      #about .pros-and-cons .right {
        width: 35%;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        position: relative; } }
    #about .pros-and-cons img.tractor.mobile {
      width: 275px;
      min-width: 65%;
      margin-left: auto;
      margin-right: -20px; }
      @media (min-width: 768px) {
        #about .pros-and-cons img.tractor.mobile {
          margin-right: -40px; } }
      @media (min-width: 992px) {
        #about .pros-and-cons img.tractor.mobile {
          display: none; } }
    #about .pros-and-cons img.tractor.desktop {
      display: none; }
      @media (min-width: 992px) {
        #about .pros-and-cons img.tractor.desktop {
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          margin-right: 0;
          right: -118px;
          width: calc(100% + 133px);
          margin-right: 0; } }
    #about .pros-and-cons p {
      margin-bottom: 20px; }
      #about .pros-and-cons p.fw-bold, #about .pros-and-cons p.btn, #about .pros-and-cons #home .events .ev-events .ev-upcoming p.ev-event-link, #home .events .ev-events .ev-upcoming #about .pros-and-cons p.ev-event-link, #about .pros-and-cons #home .events .ev-events .ev-past p.ev-event-link, #home .events .ev-events .ev-past #about .pros-and-cons p.ev-event-link {
        margin-bottom: 10px; }
    #about .pros-and-cons ul {
      list-style-type: disc;
      list-style-position: outside;
      margin-bottom: 10px; }
      @media (min-width: 992px) {
        #about .pros-and-cons ul {
          margin-bottom: 25px; } }
      #about .pros-and-cons ul li {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 13px;
        margin-left: 20px; }
        @media (min-width: 992px) {
          #about .pros-and-cons ul li {
            font-size: 24px;
            line-height: 32px; } }
  #about #initiative {
    padding-top: 60px;
    padding-bottom: 60px; }
    @media (min-width: 768px) {
      #about #initiative {
        padding-top: 120px;
        padding-bottom: 130px; } }
    #about #initiative h1 {
      margin-left: 16px;
      margin-bottom: 26px; }
      @media (min-width: 768px) {
        #about #initiative h1 {
          width: 100%;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          margin-left: 0; } }
      @media (min-width: 992px) {
        #about #initiative h1 {
          margin-bottom: 38px; } }
      @media (min-width: 1200px) {
        #about #initiative h1 {
          margin-bottom: 53px; } }
  #about .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 768px) {
      #about .flex-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  #about .box-1 {
    padding: 22px 16px 0px;
    width: 100%; }
    @media (min-width: 768px) {
      #about .box-1 {
        max-width: 532px;
        width: 45%;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto; } }
    @media (min-width: 992px) {
      #about .box-1 {
        width: 532px;
        padding-right: 50px; } }
    @media (min-width: 1200px) {
      #about .box-1 {
        width: 55%;
        padding: 30px 50px 0px 30px; } }
    #about .box-1 p {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 31px; }
      @media (min-width: 768px) {
        #about .box-1 p {
          margin-bottom: 40px; } }
      @media (min-width: 992px) {
        #about .box-1 p {
          font-size: 24px;
          line-height: 32px; } }
      @media (min-width: 1200px) {
        #about .box-1 p {
          margin-bottom: 53px; } }
    #about .box-1 img.experts {
      width: 100%; }
  #about .box-2 {
    background: -webkit-gradient(linear, left top, left bottom, from(#f08519), to(#fdd100));
    background: linear-gradient(#f08519, #fdd100);
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px); }
    @media (min-width: 768px) {
      #about .box-2 {
        max-width: 474px;
        width: 55%;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        margin-right: 0;
        margin-left: 0;
        margin-top: 90px; } }
    @media (min-width: 992px) {
      #about .box-2 {
        width: calc(45% + 30px);
        margin-left: -30px;
        margin-top: 12.5%; } }
    @media (min-width: 1200px) {
      #about .box-2 {
        margin-top: 161px;
        padding: 30px; } }
    #about .box-2 .inner {
      background-color: white;
      padding: 16px; }
      @media (min-width: 992px) {
        #about .box-2 .inner {
          padding: 20px; } }
      @media (min-width: 1200px) {
        #about .box-2 .inner {
          padding: 30px; } }
    #about .box-2 p {
      margin-bottom: 30px; }
    #about .box-2 .button-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: space-between;
          -ms-flex-align: space-between;
              align-items: space-between;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      @media (min-width: 768px) {
        #about .box-2 .button-container {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
    #about .box-2 a.button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 246px;
      height: 50px;
      margin-bottom: 15px;
      border: 2px solid #f08519;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
      margin-left: auto;
      margin-right: auto;
      white-space: nowrap;
      cursor: pointer;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      #about .box-2 a.button:first-of-type {
        background-color: #f08519; }
        #about .box-2 a.button:first-of-type:hover {
          border-color: #FE6100;
          background-color: #FE6100; }
      #about .box-2 a.button:last-of-type:hover {
        border-color: #FE6100;
        color: #FE6100; }
      @media (min-width: 768px) {
        #about .box-2 a.button:first-of-type {
          margin-right: 5px; }
        #about .box-2 a.button:last-of-type {
          margin-left: 5px; } }
      @media (min-width: 768px) {
        #about .box-2 a.button:first-of-type {
          margin-right: 8px; }
        #about .box-2 a.button:last-of-type {
          margin-left: 8px; } }

#partners {
  padding-top: 110px; }
  #partners p {
    line-height: 22px; }
  #partners .intro {
    max-width: 572px;
    margin-bottom: 40px; }
    #partners .intro h2 {
      margin-bottom: 13px; }
  #partners .group-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  #partners .group-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-left: -8px;
    margin-right: -8px; }
  #partners .group-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px; }
  #partners .group-title {
    color: #474443;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px; }
  #partners .person {
    width: calc(50% - 16px);
    margin-bottom: 40px;
    margin-right: 8px;
    margin-left: 8px; }
    @media (min-width: 576px) {
      #partners .person {
        max-width: calc(33.33% - 16px); } }
    @media (min-width: 768px) {
      #partners .person {
        width: calc(25% - 16px);
        max-width: 180px; } }
    @media (min-width: 992px) {
      #partners .person {
        width: calc(20% - 16px);
        max-width: 180px; } }
    #partners .person img {
      width: 100%;
      margin-bottom: 23px; }
    #partners .person.person--chair:after {
      display: block;
      content: 'Panel Chair';
      color: #009b4c;
      background: #fff;
      position: absolute;
      top: 150px;
      right: 10px;
      font-size: 12px;
      font-weight: 700;
      padding: 10px;
      border-radius: 15px; }
  #partners .img {
    background: #c9caca;
    height: 197px;
    width: 100%;
    margin-bottom: 23px; }

#panel-of-experts {
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    #panel-of-experts {
      margin-bottom: 90px; } }

#advisory-group {
  padding-top: 60px;
  padding-bottom: 30px;
  background-color: rgba(172, 206, 34, 0.1); }
  @media (min-width: 768px) {
    #advisory-group {
      padding-top: 130px;
      padding-bottom: 90px; } }

#partners-list {
  padding-top: 60px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    #partners-list {
      padding-top: 130px;
      padding-bottom: 90px; } }
  #partners-list h2 {
    margin-bottom: 15px; }
  #partners-list .filter-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column; }
    @media (min-width: 768px) {
      #partners-list .filter-flex {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start; } }
  #partners-list .filter-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    background: #ffe900;
    padding: 17px;
    width: 100%;
    color: #474443;
    font-size: 16px; }
    @media (min-width: 768px) {
      #partners-list .filter-controls {
        max-width: 128px; } }
  #partners-list .filter-letters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 300px; }
    @media (min-width: 768px) {
      #partners-list .filter-letters {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column; } }
    #partners-list .filter-letters a {
      display: block;
      line-height: 32px; }
      #partners-list .filter-letters a.active {
        color: #ec6b21; }
  #partners-list .filter-results {
    background: -webkit-gradient(linear, left top, left bottom, from(#f08519), to(#fdd100));
    background: linear-gradient(180deg, #f08519 0%, #fdd100 100%);
    position: relative;
    padding: 20px 20px 120px;
    margin: 0 -20px;
    min-height: 350px; }
    @media (min-width: 768px) {
      #partners-list .filter-results {
        margin: 0;
        width: 100%; } }
  #partners-list .filter-card {
    background: #ffffff;
    margin-bottom: 14px;
    padding: 16px;
    font-size: 16px;
    line-height: 22px;
    display: none; }
    #partners-list .filter-card.is-shown {
      display: block; }
  #partners-list .filter-illo {
    position: absolute;
    bottom: 0;
    right: 40px; }
    @media (min-width: 768px) {
      #partners-list .filter-illo {
        right: -15px; } }

#actions {
  overflow-x: hidden; }
  #actions .action-cat {
    font-weight: bold;
    text-decoration: underline; }
  #actions #transformation {
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: rgba(172, 206, 34, 0.1); }
    @media (min-width: 768px) {
      #actions #transformation {
        padding-top: 90px; } }
  #actions h1 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      #actions h1 {
        font-size: 36px;
        line-height: 48px; } }
  #actions p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px; }
    #actions p:last-of-type {
      margin-bottom: 0; }
    #actions p.small {
      font-size: .8em; }
  #actions .intro {
    margin: 16px 16px 30px; }
    @media (min-width: 768px) {
      #actions .intro {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 992px) {
      #actions .intro {
        margin-bottom: 0px; } }
    @media (min-width: 1200px) {
      #actions .intro {
        margin-bottom: 0px; } }
    #actions .intro p {
      width: 100%;
      max-width: 559px; }
      @media (min-width: 768px) {
        #actions .intro p {
          width: 465px;
          max-width: none; } }
      @media (min-width: 992px) {
        #actions .intro p {
          width: 65%; } }
      @media (min-width: 1200px) {
        #actions .intro p {
          width: 572px; } }
  #actions .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 768px) {
      #actions .flex-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  #actions .action-cards {
    margin: 0 -20px;
    padding-top: 30px; }
    @media (min-width: 992px) {
      #actions .action-cards {
        width: calc(100% + 120px);
        margin-left: -60px; } }
  #actions .ac-bg {
    margin-top: 90px;
    padding-bottom: 90px;
    position: relative; }
    #actions .ac-bg .ac-bg-header {
      font-size: 36px;
      line-height: 36px;
      font-weight: bold;
      margin-bottom: 10px; }
    #actions .ac-bg .illo-container {
      padding-bottom: 44px;
      position: relative; }
      #actions .ac-bg .illo-container img {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 121px;
        height: 121px; }
    #actions .ac-bg .copy-container {
      padding: 17px;
      text-align: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; }
      @media (min-width: 768px) {
        #actions .ac-bg .copy-container {
          padding: 0;
          margin: 44px auto;
          width: 60%; } }
      #actions .ac-bg .copy-container p {
        font-size: 20px;
        line-height: 26px; }
        @media (min-width: 576px) {
          #actions .ac-bg .copy-container p {
            font-size: 24px;
            line-height: 32px; } }
  #actions .ac {
    background: -webkit-gradient(linear, left top, left bottom, from(#acce22), to(#009b4c));
    background: linear-gradient(#acce22, #009b4c);
    padding: 20px;
    width: 100%;
    margin-bottom: 16px; }
    @media (min-width: 992px) {
      #actions .ac {
        padding: 30px; } }
    #actions .ac__inner {
      background: #fff;
      -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      padding: 17px;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      @media (min-width: 576px) {
        #actions .ac__inner {
          padding: 35px; } }
    #actions .ac__title {
      margin: 16px 0;
      padding-right: 30px;
      width: 100%; }
      @media (min-width: 576px) {
        #actions .ac__title {
          width: 80%; } }
    #actions .ac__top {
      margin-bottom: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      cursor: pointer; }
      @media (min-width: 768px) {
        #actions .ac__top {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row;
                  flex-flow: row; } }
      #actions .ac__top .ac__illo {
        margin-right: 30px;
        width: 121px;
        height: 121px; }
      #actions .ac__top img:last-of-type {
        margin-left: auto; }
    #actions .ac__bottom {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; }
      @media (min-width: 768px) {
        #actions .ac__bottom {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row;
                  flex-flow: row;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }
    #actions .ac__content {
      width: 100%; }
      @media (min-width: 768px) {
        #actions .ac__content {
          width: 50%;
          max-width: 441px; }
          #actions .ac__content:first-of-type {
            max-width: 404px;
            padding-right: 15px; } }
      #actions .ac__content p {
        font-size: 14px;
        line-height: 18px; }
    #actions .ac__button-flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      margin-top: 50px; }
      #actions .ac__button-flex a:first-of-type {
        margin-right: 20px; }
    #actions .ac__challenge {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 20px; }
      #actions .ac__challenge.bold {
        font-weight: bold; }
    #actions .ac__cta {
      margin-top: 50px;
      float: right; }
  #actions .coming-soon {
    margin: 70px -20px 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#acce22), to(#009b4c));
    background: linear-gradient(#acce22, #009b4c);
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 992px) {
      #actions .coming-soon {
        width: calc(100% + 120px);
        margin-left: -60px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
    #actions .coming-soon img {
      width: 121px;
      height: 121px; }
      @media (min-width: 992px) {
        #actions .coming-soon img {
          margin-left: 50px; } }
    #actions .coming-soon__title {
      font-size: 36px;
      font-weight: 500;
      line-height: 48px;
      color: #fff;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: center; }
      @media (min-width: 992px) {
        #actions .coming-soon__title {
          text-align: left;
          padding-left: 30px;
          margin-bottom: 0;
          margin-top: 0; } }
